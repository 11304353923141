.app{
    display: grid;
    place-items: center;
    background-color: #dadbd3;
    height: 100vh;
}

.app__body{
    display: flex;
    margin-top: -50px;
    width: 90vw;
    height: 90vh;
    background-color: #ededed;
    box-shadow: -1px 4px 20px -6px rgba(0,0,0,0.75);
}